import React from 'react';
// import ContactoModal from '../components/Modal/ContactoModal';
import { useCustomState } from '../hooks/useCustomState';
import GlobalContext from './GlobalContext';

function ContextProviderCompound({ children }) {
  const [state, setState] = useCustomState({
    showModal: false,
    typeModal: null,
    navigate: null,
    propsModal: null,
  });
  const { showModal, typeModal, navigate, propsModal } = state;

  const toggleModal = (modalType, navigate = null, propsModal = null) => {
    setState({
      showModal: !showModal,
      typeModal: modalType,
      navigate,
      propsModal,
    });
  };

  return (
    <GlobalContext.Provider value={{ showModal, typeModal, navigate, propsModal, setModal: toggleModal }}>
      {/* <ContactoModal /> */}
      {children}
    </GlobalContext.Provider>
  );
}

export default ContextProviderCompound;