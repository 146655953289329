import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@mantine/core';
import { ReactComponent as MailIcon } from '../../assets/icons/icon-mail.svg';
import { ReactComponent as PasswordIcon } from '../../assets/icons/icon-pass.svg';
import { ReactComponent as HideIcon } from '../../assets/icons/icon-hide.svg';
import { ReactComponent as ViewIcon } from '../../assets/icons/icon-view.svg';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import { useCustomState } from '../../hooks/useCustomState';
import { loginUser } from '../../redux/reducers/admin/authSlice';
import pathsRouter from '../../router/pathsRouter';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.admin.auth);
  const [state, setState] = useCustomState({
    sending: false,
    form: {
      correo_electronico: "",
      contrasenia: "",
    },
  });

  useEffect(() => {
    if (isAuthenticated) navigate(`${pathsRouter.pathAdmin}/`, { replace: true });
  }, [isAuthenticated, navigate]);

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setState({ sending: true });
    dispatch(loginUser({ email: state.form.correo_electronico, password: state.form.contrasenia }, { showAlert: true }))
      .then(_ => {
        setState({ sending: false });
      });
  }, [state.form, dispatch, setState]);

  return (
    <>
      <SEO
        title="Iniciar sesión"
      />
      <div className="min-h-screen bg-Magnolia bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: `url('/assets/images/login-backimg.jpg')` }}>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white py-12 px-4 shadow rounded-md sm:px-10">
            <img alt="Logo" src={"/assets/images/logo.svg"} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-startup-semibold text-primary text-center text-2xl'>INICIA SESIÓN</h1>
            <form className="form-login space-y-3 mt-7" onSubmit={onSubmit}>
              <div
                className='bg-bg px-2 py-1 relative rounded-md text-sm text-gray-900 w-full'
              >
                <Flex
                  // gap="xs"
                  align="center"
                  direction="row"
                  wrap="nowrap"
                >
                  <div
                    className='bg-white p-2 rounded-md'
                  >
                    <MailIcon width={12} />
                  </div>
                  <input
                    name="correo_electronico"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder='Correo electrónico'
                    className="bg-transparent !border-0 !outline-none !ring-0 placeholder-gray-500 text-sm text-gray-900 pr-1 w-full"
                    value={state?.form?.correo_electronico}
                    onChange={(event) => onChange({ correo_electronico: event.target.value })}
                  />
                </Flex>
              </div>
              <div
                className='bg-bg pl-2 py-1 relative rounded-md text-sm text-gray-900 w-full'
              >
                <Flex
                  // gap="xs"
                  align="center"
                  direction="row"
                  wrap="nowrap"
                >
                  <div
                    className='bg-white p-2 rounded-md'
                  >
                    <PasswordIcon width={12} />
                  </div>
                  <input
                    name="contrasenia"
                    type={state.isVisiblePassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    placeholder='Contraseña'
                    className="bg-transparent !border-0 !outline-none !ring-0 placeholder-gray-500 text-sm text-gray-900 pr-1 w-full"
                    value={state?.form?.contrasenia}
                    onChange={(event) => onChange({ contrasenia: event.target.value })}
                  />
                  <div
                    className='p-3 pl-2 cursor-pointer active:scale-95 transition-all'
                    onClick={() => setState({ isVisiblePassword: !state.isVisiblePassword })}
                  >
                    {state.isVisiblePassword ? <HideIcon width={14} /> : <ViewIcon width={14} />}
                  </div>
                </Flex>
              </div>
              <div className="flex justify-end">
                <p className='inline-block font-startup-medium text-right text-muted text-primary cursor-pointer p-1' onClick={() => navigate(`${pathsRouter.pathAdmin}/recuperar-contrasenia`)}>
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="INGRESAR"
                  classNameContainer="inline-flex justify-center min-w-[140px] !mt-6 !border-0 w-full"
                  classNameButton="rounded-xl py-4 px-10 !border-0 !bg-primary hover:!bg-primaryHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;