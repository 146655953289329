import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
// import parseErrorMessage from '../../../utils/parseErrorMessage';
// import { createPerfiles } from '../../../api/admin/perfiles';
import { isEmpty } from '../../../utils';
import pathsRouter from '../../../router/pathsRouter';
const prefix = "permisos";

const ModificarPermiso = ({ isEditar, isCrear }) => {
  const isDisabled = (!isEditar && !isCrear);
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    sending: false,
    nombre_permiso: "",
    permisos: [],
  });

  useEffect(() => {
  }, []);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onCreate = useCallback(async () => {
    console.log(state.permisos);
    if (isEmpty(state.nombre_permiso)) return message(null, 'El nombre del permiso es obligatorio.');

    // try {
    //   setState({ sending: true });
    //   const response = await createPerfiles({
    //     name: state.nombre_permiso,
    //     ...state.permisos
    //   });
    //   setState({ sending: false });
    //   if (response?.status === true) {
    //     window.PrimeToast.onHide = () => navigate('/admin/perfiles');
    //     message('Perfil creado', '¡El perfil se ha creado exitosamente! Serás redirigido a la página de perfiles en breve...');
    //   } else {
    //     message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
    //   }
    // } catch (error) {
    //   setState({ sending: false });
    //   message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
    // }

    // eslint-disable-next-line
  }, [state.nombre_permiso, state.permisos, setState, navigate, message]);

  const RolesDesign = useCallback(({ name, value, onChange }) => (
    <div className='flex gap-2 w-full'>
      <MultiSelect
        label={name}
        labelProps={{ mb: 5 }}
        classNames={{
          root: "max-w-[420px] w-full",
          inputField: "!ring-0 !outline-none"
        }}
        variant="default"
        data={["Ver", "Crear", "Editar", "Eliminar"]}
        value={value}
        onChange={onChange}
        clearable
        disabled={isDisabled}
      />
    </div>
  ), [isDisabled]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar permiso" : (isCrear ? "Crear nuevo permiso" : "Ver permiso")}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar permiso" : (isCrear ? "Crear nuevo permiso" : "Ver permiso")}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
              <div className='mb-6'>
                <input
                  type="text"
                  placeholder='Nombre del permiso'
                  className="bg-bg border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.nombre_permiso}
                  onChange={(event) => setState({ nombre_permiso: event.target.value })}
                  disabled={isDisabled}
                />
              </div>
              <p className='font-startup-medium text-primary mb-3 text-xl text-center'>Asignar permisos:</p>
              <div className='flex flex-col gap-6 w-full'>
                <RolesDesign
                  name="Usuarios"
                  onChange={value => setState({ usersPermissions: value }, "permisos")}
                />
                <RolesDesign
                  name="Permisos"
                  onChange={value => setState({ permisosPermissions: value }, "permisos")}
                />
                <RolesDesign
                  name="Colaboradores"
                  onChange={value => setState({ colaboradoresPermissions: value }, "permisos")}
                />
                <RolesDesign
                  name="Acciones"
                  onChange={value => setState({ accionesPermissions: value }, "permisos")}
                />
                <RolesDesign
                  name="Recompensas"
                  onChange={value => setState({ recompensasPermissions: value }, "permisos")}
                />
                <RolesDesign
                  name="Reportes"
                  onChange={value => setState({ reportesPermissions: value }, "permisos")}
                />
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={(isEditar || isCrear) ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {(isEditar || isCrear) && (
                  <ButtonCustom
                    text={isEditar ? "EDITAR" : "CREAR"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onCreate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ModificarPermiso;