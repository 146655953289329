import React from "react";
import { Button } from '@mantine/core';
import { ReactComponent as IconoBack } from "../assets/icons/flechaback-icon.svg";
import { ReactComponent as IconoIr } from "../assets/icons/flecha-icon.svg";

export const ButtonPrimary = ({ disabled, text, withIcon, icon, withIconBack, iconBack, type, loading, loadingText, onClick, onSubmit, classNameContainer, classNameButton, styleContainer, classNameTextLoading, theme }) => (
  <div className={`${classNameContainer ? classNameContainer : ""} ${theme === 'white' ? "border-white text-primary" : "border-primary"}`} style={styleContainer}>
    <Button
      className={`h-auto min-h-[45px] flex items-center font-startup-semibold text-sm border-transparent ${theme === 'white' ? 'bg-white hover:bg-white text-primary' : 'bg-primary hover:bg-primary text-white'} py-2.5 px-4 ${classNameButton}`}
      // classNames={{
      //   inner: "py-1"
      // }}
      onClick={onClick}
      onSubmit={onSubmit}
      type={type}
      loading={loading}
      disabled={disabled}
    >
      {!(loading) ? (
        <>
          {withIconBack && (
            <span className="d-block mr-3">
              <IconoBack fill={iconBack?.fill || (theme === 'white' ? "#7e73d6" : "#fff")} width={iconBack?.width || 18} {...iconBack?.props} />
            </span>
          )}
          {text || ""}
          {withIcon && (
            <span className="d-block ml-3">
              <IconoIr fill={icon?.fill || (theme === 'white' ? "#7e73d6" : "#fff")} width={icon?.width || 18} {...icon?.props} />
            </span>
          )}
        </>
      ) : (
        <>
          {loadingText && <p className={`${classNameTextLoading}`} style={{ margin: 0, marginLeft: 10 }}>{loadingText || "Cargando"}</p>}
        </>
      )}
    </Button>
  </div>
);

export const ButtonCustom = (props) => {
  const { text, withIconBack, icon, iconBack, iconLeft, iconRight, type, loading, disabled, loadingText, onClick, onSubmit, classNameContainer, classNameButton, styleContainer, styleButton, classNameText, classNameTextLoading } = props;

  // Clases por defecto
  const defaultClassesButton = 'h-auto flex items-center font-startup-medium text-sm py-2.5';

  return (
    <div className={classNameContainer} style={styleContainer}>
      <Button
        className={`${defaultClassesButton} ${classNameButton}`}
        onClick={onClick}
        onSubmit={onSubmit}
        type={type}
        loading={loading}
        icon={icon}
        style={styleButton}
        disabled={disabled}
      >
        {!(loading) ? (
          <>
            {withIconBack && (
              <span className="d-block mr-3">
                <IconoBack fill={iconBack?.fill || "#525252"} width={iconBack?.width || 16} {...iconBack?.props} />
              </span>
            )}
            {iconLeft && (
              <span className="d-block mr-3">
                {iconLeft}
              </span>
            )}
            <div className={classNameText}>
              {text || ""}
            </div>
            {iconRight && (
              <span className="d-block ml-3">
                {iconRight}
              </span>
            )}
          </>
        ) : (
          <>
            {loadingText && <p className={classNameTextLoading} style={{ marginLeft: 10 }}>{loadingText || "Cargando"}</p>}
          </>
        )}
      </Button>
    </div>
  );
};