import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import pathsRouter from './pathsRouter';
import { getProfile } from '../api/auth';
import { logout as logoutAdmin } from '../redux/reducers/admin/authSlice';

import ScrollToTop from '../utils/ScrollToTop';

import NoEncontrado from "../pages/Errors/404";

import AdminHome from '../pages/Admin/Home';
import Login from '../pages/Admin/Login';
import RecuperarCuenta from '../pages/Admin/RecuperarCuenta';
import ActualizarContrasenia from '../pages/Admin/ActualizarContrasenia';

import Usuarios from '../pages/Admin/Usuarios';
import ModificarUsuario from '../pages/Admin/Usuarios/ModificarUsuario';
import Permisos from '../pages/Admin/Permisos';
import ModificarPermiso from '../pages/Admin/Permisos/ModificarPermiso';
import Colaboradores from '../pages/Admin/Colaboradores';

function RootRouter(props) {
  const dispatch = useDispatch();
  const {
    admin: { auth: admin },
  } = useSelector(state => state);

  const { pathAdmin } = pathsRouter;

  React.useLayoutEffect(() => {
    const _admin = admin?.user?.user;
    if (_admin?.id && _admin?.email) {
      (async () => {
        try {
          await getProfile({ id: _admin?.id, email: _admin?.email }, true);
        } catch (error) {
          if (error.response?.status === 401 || error.response?.status === 404) {
            dispatch(logoutAdmin());
          }
        }
      })();
    };
    // eslint-disable-next-line
  }, [admin?.user?.user?.id, admin?.user?.user?.email]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path={`${pathAdmin}`} element={admin.isAuthenticated ? <AdminHome /> : <Login />} />
        <Route exact path={`${pathAdmin}/login`} element={<Login />} />
        <Route exact path={`${pathAdmin}/recuperar-contrasenia`} element={<RecuperarCuenta />} />
        <Route exact path={`${pathAdmin}/actualizar-contrasenia`} element={<ActualizarContrasenia />} />

        {admin.isAuthenticated && (
          <>
            <Route exact path={`${pathAdmin}/usuarios`} element={<Usuarios />} />
            <Route exact path={`${pathAdmin}/usuarios/:id`} element={<ModificarUsuario />} />
            <Route exact path={`${pathAdmin}/usuarios/crear-usuario`} element={<ModificarUsuario isCrear />} />
            <Route exact path={`${pathAdmin}/usuarios/editar-usuario/:id`} element={<ModificarUsuario isEditar />} />
            <Route exact path={`${pathAdmin}/permisos`} element={<Permisos />} />
            <Route exact path={`${pathAdmin}/permisos/:id`} element={<ModificarPermiso />} />
            <Route exact path={`${pathAdmin}/permisos/crear-permiso`} element={<ModificarPermiso isCrear />} />
            <Route exact path={`${pathAdmin}/permisos/editar-permiso/:id`} element={<ModificarPermiso isEditar />} />
            <Route exact path={`${pathAdmin}/colaboradores`} element={<Colaboradores />} />
          </>
        )}

        <Route path="*" element={<NoEncontrado />} />
      </Routes>
    </Router>
  );
}

export default React.memo(RootRouter);