import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ title, description, name, site_name, type }) {
  return (
    <Helmet
      titleTemplate="%s - Prosazón"
    >
      { /* Standard metadata tags */}
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel="canonical" href="https://jimefitmom.com" />
      <link rel="alternate" type="application/rss+xml" title="Prosazón &raquo; Feed" href="https://jimefitmom.com/feed/" />
      <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
      { /* End standard metadata tags */}

      { /* Facebook tags */}
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content={type || "website"} />
      <meta property="og:title" content={title || "Prosazón"} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={site_name || "Prosazón"} />
      <meta property="article:publisher" content="https://www.facebook.com/jimefitmom" />
      { /* End Facebook tags */}

      { /* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */}
    </Helmet>
  )
}