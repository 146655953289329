import QueryString from "qs";
import axios from ".";

export const forgot = async (credentials, isAdmin) => {
  try {
    const response = await axios.post(`/${isAdmin ? "admin/auth/forgot" : "auth/forgot"}`, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotVerify = async (credentials, isAdmin) => {
  try {
    const response = await axios.post(`/${isAdmin ? "admin/auth/update-password" : "auth/update-password"}`, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (credentials) => {
  try {
    const response = await axios.post(`/auth/register`, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (credentials, isAdmin) => {
  try {
    const response = await axios.post(`/${isAdmin ? "admin/auth/login" : "auth/login"}`, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async (id) => {
  try {
    const response = await axios.post(`/auth/logout`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProfile = async (params, isAdmin) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`/${isAdmin ? `admin/auth/get_profile?${_params}` : `auth/get_profile?${_params}`}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};