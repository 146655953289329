import { Button, Flex, Modal } from '@mantine/core';
import { ReactComponent as TrashIcon } from '../../assets/icons/icon-trash.svg';

export default function EliminarModal({ opened, onClose, withIcon, title, description, rejectLabel, acceptLabel, onReject, onAccept }) {

  return (
    <Modal classNames={{ content: "shadow" }} centered opened={opened} onClose={onClose} size="sm" withCloseButton={false}>
      <div className='flex flex-col items-center justify-center gap-2 max-w-[320px] mx-auto px-4 py-2'>
        {withIcon !== false && (<TrashIcon height={64} width={64} />)}
        {title && (<h1 className="font-startup-bold text-lg text-center">{title}</h1>)}
        {description && (<p className="font-startup-medium text-muted text-gray-700 text-center">{description}</p>)}
        <Flex className='w-full' gap={16} mt={30}>
          <Button
            variant="filled"
            className='bg-white border border-gray-300 hover:bg-gray-100 text-sm text-text h-auto py-2.5 transition-all'
            fullWidth
            onClick={onReject}
          >
            {rejectLabel || "Cancelar"}
          </Button>
          <Button
            variant="filled"
            className='bg-button border border-button hover:bg-buttonHover text-sm text-white h-auto py-2.5 transition-all'
            fullWidth
            onClick={onAccept}
          >
            {acceptLabel || "Eliminar"}
          </Button>
        </Flex>
      </div>
    </Modal>
  );
}