import React from 'react';
import { Loader } from '@mantine/core';

const Loading = ({ containerClassName, containerStyle, textClassName, onlyIcon }) => (
  <div className={`flex items-center justify-center space-x-4 ${containerClassName}`} style={containerStyle}>
    <Loader color={"blue"} size={"xs"} />
    {onlyIcon !== true && (
      <p className={`font-startup-medium text-Crayola flex text-md ${textClassName}`}>
        <span>Cargando</span>
        <span className='inline-block animate__animated animate__infinite animate__flash animate__fast'>.</span>
        <span className='inline-block animate__animated animate__infinite animate__flash animate__slower'>.</span>
        <span className='inline-block animate__animated animate__infinite animate__flash animate__slow'>.</span>
      </p>
    )}
  </div>
);

export const LoadingFloat = () => (
  <div className="animate__animated animate__fadeIn animate__fast fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0 bg-gray-900 opacity-75" />
    <div className="z-10 flex flex-col items-center justify-center gap-4">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-gray-200" />
      <p className='animate-pulse font-startup-medium text-white'>Espere un momento...</p>
    </div>
  </div>
)

export default Loading;