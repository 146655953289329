import { useState, useCallback } from 'react';

/**
  Hook personalizado para manejar el estado de componentes de React.
  @param {Object} initialState El estado inicial del componente.
  @returns {Array} Un arreglo con tres elementos: el estado actual, la función para actualizar el estado y la función para eliminar elementos del estado.
  @example
  const [state, setState, deleteFromState] = useCustomState({
    data: [
      { id: 1, title: "Título 1" },
      { id: 2, title: "Título 2" },
      { id: 3, title: "Título 3" }
    ],
    params: { active: true }
  });
  setState({ active: false }, "params");
  setState({ title: "Nuevo Título" }, "data", 1);
  deleteFromState(0, "data");
  */
export const useCustomState = (initialState) => {
  const [state, setState] = useState(initialState);

  /**
    Función para actualizar el estado.
    @param {Object} newState El nuevo estado que se desea asignar.
    @param {String} key (Opcional) La clave del objeto que se desea actualizar.
    @param {Number} index (Opcional) El índice del elemento del arreglo que se desea actualizar.
    */
  const updateState = useCallback((newState, key, index) => {
    if (key && index !== undefined) {
      setState(prevState => {
        const prevStateForKey = prevState[key] || [];
        const updatedStateForKey = [
          ...prevStateForKey.slice(0, index),
          { ...prevStateForKey[index], ...newState },
          ...prevStateForKey.slice(index + 1)
        ];
        return { ...prevState, [key]: updatedStateForKey };
      });
    } else if (key) {
      if (key.indexOf(".") !== -1) {
        setState(prevState => {
          const keys = key.split('.');
          const lastIndex = keys.length - 1;
          let currentState = { ...prevState };
          let currentObject = currentState;
          for (let i = 0; i < lastIndex; i++) {
            const currentKey = keys[i];
            currentObject[currentKey] = { ...currentObject[currentKey] };
            currentObject = currentObject[currentKey];
          }
          currentObject[keys[lastIndex]] = { ...currentObject[keys[lastIndex]], ...newState };
          return currentState;
        });
      } else {
        setState(prevState => ({ ...prevState, [key]: { ...prevState[key], ...newState } }));
      }
    } else {
      setState(prevState => ({ ...prevState, ...newState }));
    }
  }, []);

  /**
    Función para eliminar elementos del estado.
    @param {Number} index El índice del elemento del arreglo que se desea eliminar.
    @param {String} key La clave del objeto que contiene el arreglo.
    */
  const deleteFromState = useCallback((index, key) => {
    setState((prevState) => {
      const prevStateForKey = prevState[key] || [];
      const updatedStateForKey = [...prevStateForKey];
      updatedStateForKey.splice(index, 1);
      return { ...prevState, [key]: updatedStateForKey };
    });
  }, []);

  return [state, updateState, deleteFromState];
};