import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import { useCustomState } from '../../hooks/useCustomState';
import { forgotUser } from '../../redux/reducers/admin/authSlice';
// import { optionsToast } from '../../config/toast';
import parseErrorMessage from '../../utils/parseErrorMessage';
import pathsRouter from '../../router/pathsRouter';
import { isEmpty } from '../../utils';

const ActualizarContrasenia = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [state, setState] = useCustomState({
    sending: false,
    form: {
      password: "",
      confirm_password: "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const message = (message) => {
    // window.PrimeToast?.show({
    //   ...optionsToast(),
    //   summary: summary,
    //   detail: detail,
    // });
    setState({ message: message });
  }

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (isEmpty(state.form?.password) || isEmpty(state.form?.confirm_password)) return message("Las contraseñas no pueden estar vacías.");
    if (state.form?.password !== state.form?.confirm_password) return message("Las contraseñas no coinciden.");

    setState({ sending: true });
    dispatch(forgotUser({ ...state.form, email, code: token }, { verify: true }))
      .then((response) => {
        setState({ sending: false });
        if (response?.status === true) {
          message('Su contraseña ha sido actualizada correctamente. Si necesita ayuda adicional, no dude en contactarnos. ¡Gracias!');
        } else {
          const _message = parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la contraseña. Por favor, inténtalo de nuevo más tarde.';
          message(_message);
        }
      });
  }, [state.form, email, token, dispatch, setState]);

  return (
    <>
      <SEO
        title="Actualizar contraseña"
      />
      <div className="min-h-screen bg-bg bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: `url('/assets/images/login-backimg.jpg')` }}>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white pt-12 pb-9 px-4 shadow rounded-md sm:px-10">
            <img alt="Logo" src={"/assets/images/logo.svg"} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-startup-semibold text-primary text-center text-2xl'>
              ACTUALIZA TU
              <p>CONTRASEÑA</p>
            </h1>
            <form className="form-login space-y-3 mt-7" onSubmit={onSubmit}>
              <input
                type="password"
                autoComplete="current-password"
                required
                placeholder='Contraseña'
                className="bg-bg border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.password}
                onChange={(event) => onChange({ password: event.target.value })}
              />
              <input
                type="password"
                required
                placeholder='Confirmar contraseña'
                className="bg-bg border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.confirm_password}
                onChange={(event) => onChange({ confirm_password: event.target.value })}
              />
              {!isEmpty(state.message) && (
                <p className='text-muted text-gray-500'>{state.message}</p>
              )}
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="ENVIAR"
                  classNameContainer="inline-flex justify-center min-w-[140px] !mt-6 !border-0"
                  classNameButton="py-3 px-10 !rounded-full !border-0 !bg-Orchid hover:!bg-OrchidHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
              <div className="flex justify-center pt-2">
                <p className='inline-block font-startup text-center text-muted text-Orchid underline cursor-pointer p-1' onClick={() => navigate(`${pathsRouter.pathAdmin}/`)}>
                  Cancelar
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActualizarContrasenia;