import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PasswordInput, Select, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
// import parseErrorMessage from '../../../utils/parseErrorMessage';
// import { createPerfiles } from '../../../api/admin/perfiles';
import { isEmpty } from '../../../utils';
import pathsRouter from '../../../router/pathsRouter';
const prefix = "usuarios";

const ModificarUsuario = ({ isEditar, isCrear }) => {
  const isDisabled = (!isEditar && !isCrear);
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    sending: false,
    nombre_usuario: "",
    usuarios: [],
  });

  useEffect(() => {
  }, []);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onCreate = useCallback(async () => {
    console.log(state.usuarios);
    if (isEmpty(state.nombre_usuario)) return message(null, 'El nombre del usuario es obligatorio.');

    // try {
    //   setState({ sending: true });
    //   const response = await createPerfiles({
    //     name: state.nombre_usuario,
    //     ...state.usuarios
    //   });
    //   setState({ sending: false });
    //   if (response?.status === true) {
    //     window.PrimeToast.onHide = () => navigate('/admin/perfiles');
    //     message('Perfil creado', '¡El perfil se ha creado exitosamente! Serás redirigido a la página de perfiles en breve...');
    //   } else {
    //     message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
    //   }
    // } catch (error) {
    //   setState({ sending: false });
    //   message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
    // }

    // eslint-disable-next-line
  }, [state.nombre_usuario, state.usuarios, setState, navigate, message]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar usuario" : (isCrear ? "Crear nuevo usuario" : "Ver usuario")}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar usuario" : (isCrear ? "Crear nuevo usuario" : "Ver usuario")}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
              <div className='flex flex-col gap-5 mb-6'>
                <TextInput
                  size="md"
                  variant='filled'
                  label="Nombre"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.nombre}
                  onChange={(event) => setState({ nombre: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <TextInput
                  size="md"
                  variant='filled'
                  label="Apellidos"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.apellidos}
                  onChange={(event) => setState({ apellidos: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <TextInput
                  size="md"
                  variant='filled'
                  label="Correo electrónico"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.email}
                  onChange={(event) => setState({ email: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <Select
                  size="md"
                  variant='filled'
                  label="Asignar perfil"
                  placeholder="Selecciona"
                  data={['Super admin', 'Marketing', 'Moderador']}
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  disabled={isDisabled}
                />
                <PasswordInput
                  size="md"
                  variant="filled"
                  label="Contraseña"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    wrapper: "h-auto",
                    label: "text-sm",
                    input: "text-sm",
                    innerInput: "text-sm bg-[#f1f3f5]",
                  }}
                  value={state?.password}
                  onChange={(event) => setState({ password: event.currentTarget.value })}
                  disabled={isDisabled}
                />
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={(isEditar || isCrear) ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {(isEditar || isCrear) && (
                  <ButtonCustom
                    text={isEditar ? "ACTUALIZAR" : "GUARDAR"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onCreate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ModificarUsuario;