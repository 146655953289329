import axios from 'axios';
import { API_BASE_URL } from '../config/app.js';

// Crear instancia de Axios
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

const getToken = () => {
  const store = require('../redux/store/index.js').store;
  const token = store?.getState()?.admin?.auth?.isAuthenticated ? store?.getState()?.admin?.auth?.user?.jwt : ""; // Obtener el token de Redux desde el estado

  return token;
}

// Agregar interceptor de solicitud
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Agregar encabezado de autorización
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axios, getToken };
export default axiosInstance;