import { createSlice } from '@reduxjs/toolkit';
import { forgot, forgotVerify, login, register } from '../../api/auth';
import parseErrorMessage from '../../utils/parseErrorMessage';

const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
  },
});

export const { setUser, setError, logout } = authSlice.actions;

export const loginUser = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await login(credentials);
      dispatch(setUser(response?.data));
      return response;
    } catch (error) {
      dispatch(setError(error.message));
      return error;
    }
  };
};

export const registerUser = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await register(credentials);
      dispatch(setUser(response?.data));
      return response;
    } catch (error) {
      dispatch(setError(error.message));
      return error;
    }
  };
};

export const forgotUser = (credentials, options) => {
  return async (dispatch) => {
    try {
      const response = await (options?.verify ? forgotVerify(credentials) : forgot(credentials));
      return response;
    } catch (error) {
      dispatch(setError(parseErrorMessage(error)));
      if (options?.showAlert) {
      }
      return error;
    }
  };
};

export default authSlice.reducer;