import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@mantine/core';
import { ReactComponent as MailIcon } from '../../assets/icons/icon-mail.svg';
import { ReactComponent as PrevIcon } from '../../assets/icons/flechaback-icon.svg';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import { useCustomState } from '../../hooks/useCustomState';
import { forgotUser } from '../../redux/reducers/admin/authSlice';
import pathsRouter from '../../router/pathsRouter';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { isEmpty } from '../../utils';
import { colors } from '../../theme/colors';

const RecuperarCuenta = () => {
  const [state, setState] = useCustomState({
    sending: false,
    message: null,
    form: {
      email: "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (isEmpty(state.form?.email)) return setState({ message: "El correo electrónico no puede estar vacío." });

    setState({ sending: true, message: null });
    dispatch(forgotUser(state.form))
      .then((response) => {
        setState({ sending: false });
        // if (response?.status === true) {
        if (response?.id) {
          setState({ message: "Se ha enviado un correo de recuperación de contraseña. Por favor, revisa tu bandeja de entrada." });
        } else {
          const _message = parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al enviar el correo electrónico. Por favor, inténtalo de nuevo más tarde.';
          setState({ message: _message });
        }
      });
  }, [state.form, dispatch, setState]);

  return (
    <>
      <SEO
        title="Recuperar contraseña"
      />
      <div className="min-h-screen bg-bg bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white shadow-sm pt-12 pb-9 px-4 rounded-md sm:px-10">
            <img alt="Logo" src={"/assets/images/logo.svg"} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-startup-semibold text-primary text-center text-2xl'>
              RECUPERA TU
              <p>CONTRASEÑA</p>
            </h1>
            <form className="form-login space-y-6 mt-7" onSubmit={onSubmit}>
              <div>
                <div
                  className='bg-bg px-2 py-1 relative rounded-md text-sm text-gray-900 w-full'
                >
                  <Flex
                    // gap="xs"
                    align="center"
                    direction="row"
                    wrap="nowrap"
                  >
                    <div
                      className='bg-white p-2 rounded-md'
                    >
                      <MailIcon width={12} />
                    </div>
                    <input
                      name="correo_electronico"
                      type="email"
                      autoComplete="email"
                      required
                      placeholder='Correo electrónico'
                      className="bg-transparent !border-0 !outline-none !ring-0 placeholder-gray-500 text-sm text-gray-900 pr-1 w-full"
                      value={state?.form?.email}
                      onChange={(event) => onChange({ email: event.target.value })}
                    />
                  </Flex>
                </div>
                {!isEmpty(state.message) && (
                  <p className='mt-3 text-muted text-gray-500'>{state.message}</p>
                )}
              </div>
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="ENVIAR"
                  classNameContainer="flex justify-center w-full min-w-[140px] !mt-2 !border-0"
                  classNameButton="py-3 px-10 !rounded-lg !border-0 !bg-button hover:!bg-buttonHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
              <div className="flex justify-center pt-6">
                <p className='inline-flex items-center gap-1 font-startup-medium text-center text-muted text-primary cursor-pointer p-1' onClick={() => navigate(`${pathsRouter.pathAdmin}/login`)}>
                  <PrevIcon fill={colors.primary} height={8} />
                  <span>Volver a inicio de sesión</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecuperarCuenta;