import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import theme from './theme';
import ContextProviderCompound from './contexts';
import { persistor, store } from './redux/store/index';
import RootRouter from './router/RootRouter';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContextProviderCompound>
          <MantineProvider theme={theme}>
            <Notifications autoClose={false} position="top-right" />
            <RootRouter />
          </MantineProvider>
        </ContextProviderCompound>
      </PersistGate>
    </Provider>
  );
}

export default App;