const parseErrorMessage = (error) => {
  try {
    if (error?.response?.status === 404) {
      return "No se encontraron resultados.";
    } else if (error?.response?.data?.errors) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key) && Array.isArray(errors[key]) && errors[key].length > 0) {
          return errors[key][0];
        } else if (errors.hasOwnProperty(key)) {
          return errors[key];
        }
      }
    }

    if (error?.response?.data?.message) {
      return error.response.data.message;
    } else {
      return "Hubo un error al procesar la solicitud. Vuelva a intentarlo más tarde.";
    }
  } catch (error) {
    return null;
  }
};

export default parseErrorMessage;